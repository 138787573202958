import React from 'react';

import { Main } from '@layout';
import { Seo } from '@common';
import { Hero, CardGrid } from '@home';

export default function IndexPage() {
  return (
    <Main>
      <Seo description="Tar & Partner Consulting, your reliable consulting partner on cloud technologies" />
      <Hero />
      {/* <CardGrid /> */}
    </Main>
  );
}
